<template>
  <div>
    <b-row class="mt-0 mt-lg-3">
      <b-col xl="3">
        <card :card="mod" :link="false" :seeMore="true" />
      </b-col>
      <b-col xl="9">
        <b-row>
          <b-col xl="12">
            <tabs
              :tabs="tabs"
              :title="$t('generic.modules.security.title')"
              style="min-height: 100%;"
              v-if="tabs.length > 0"
            />
            <div v-else>
              <b-card
                class="card-custom gutter-b"
                body-class="p-0 d-flex flex-column"
                header-class="border-0 pt-5"
              >
                <template #header>
                  <b-card-title class="font-weight-bolder">
                    <div class="card-label">
                      {{ $t("profile.security.help.title") }}
                    </div>
                  </b-card-title>
                </template>
                <div class="card-body text-left">
                  <p
                    v-for="(value, key) in $t(
                      'profile.security.help.description'
                    )"
                    :key="key"
                    v-html="value"
                  />
                </div>
              </b-card>
              <empty-card type="profile.socialEngineering" />
            </div>
          </b-col>
        </b-row>
        <b-row>
          <b-col
            xl="4"
            class="mb-8 mt-8"
            v-for="interaction in visibleInteractions"
            :key="interaction.surveyEntityId"
          >
            <card :card="interaction" :date="interaction.date" />
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import _ from "lodash";
import { mapActions, mapGetters } from "vuex";

import { getUserActions } from "@/api/user/traits.api";

import Card from "@/components/profile/Card.component";
import Tabs from "@/components/training/Tabs.component";
import EmptyCard from "@/components/EmptyCard.component.vue";

export default {
  name: "Security",
  components: {
    Card,
    Tabs,
    EmptyCard
  },
  methods: {
    ...mapActions("Profile", ["loadProfile"]),
    ...mapActions("Interactions", ["loadInteractions"]),
    mount() {
      if (this.isConstantsActive && !this.isProfileLoaded) this.loadProfile();
      if (this.isConstantsActive && !this.isInteractionsLoaded)
        this.loadInteractions();
      if (this.isConstantsActive) this.loadRecommendations();
    },
    loadRecommendations() {
      var params = {
        surveyTypes: [
          this.getConstant("SURVEY_KYMATIO_GRI_CHALLENGE"),
          this.getConstant("SURVEY_KYMATIO_GRI_COMMITMENT"),
          this.getConstant("SURVEY_KYMATIO_GRI_TRUST"),
          this.getConstant("SURVEY_KYMATIO_GRI_PRAGMATISM"),
          this.getConstant("SURVEY_KYMATIO_GRI_DISSATISFACTION"),
          this.getConstant("SURVEY_KYMATIO_GRI_NEGLIGENCE"),
          this.getConstant("SURVEY_KYMATIO_GRI_EXPEDITION"),
          this.getConstant("SURVEY_KYMATIO_GRI_OVERLOAD"),
          this.getConstant("SURVEY_KYMATIO_GRI_DIVERGENCE"),
          this.getConstant("SURVEY_KYMATIO_GRI_AMBITION"),
          this.getConstant("SURVEY_KYMATIO_GRI_GLOBAL")
        ],
        groupDimensions: [
          this.getConstant("DIMENSION_GROUP_GRIPLUS"),
          this.getConstant("DIMENSION_GROUP_ACTION_PLAN"),
          this.getConstant("DIMENSION_GROUP_INSIDER")
        ],
        actionTypes: [
          this.getConstant("ACTIONS_TYPE_RECOMMENDATION"),
          this.getConstant("ACTIONS_TYPE_PILLS")
        ],
        entityType: this.getConstant("ENTITY_TYPE_USER"),
        maxValue: "YES"
      };
      getUserActions(params).then(res => {
        this.tabs = res.data.records;
      });
    }
  },
  data() {
    return {
      object: {},
      tabs: []
    };
  },
  computed: {
    ...mapGetters("Profile", ["isProfileLoaded", "modules"]),
    ...mapGetters("Constants", ["isConstantsActive", "getConstant"]),
    ...mapGetters("Interactions", [
      "isInteractionsLoaded",
      "interactions",
      "getBySurveyType"
    ]),
    visibleInteractions() {
      return this.getBySurveyType([
        parseInt(this.getConstant("SURVEY_KYMATIO_GRI_CHALLENGE")),
        parseInt(this.getConstant("SURVEY_KYMATIO_GRI_COMMITMENT")),
        parseInt(this.getConstant("SURVEY_KYMATIO_GRI_TRUST")),
        parseInt(this.getConstant("SURVEY_KYMATIO_GRI_PRAGMATISM")),
        parseInt(this.getConstant("SURVEY_KYMATIO_GRI_DISSATISFACTION")),
        parseInt(this.getConstant("SURVEY_KYMATIO_GRI_NEGLIGENCE")),
        parseInt(this.getConstant("SURVEY_KYMATIO_GRI_EXPEDITION")),
        parseInt(this.getConstant("SURVEY_KYMATIO_GRI_OVERLOAD")),
        parseInt(this.getConstant("SURVEY_KYMATIO_GRI_DIVERGENCE")),
        parseInt(this.getConstant("SURVEY_KYMATIO_GRI_AMBITION")),
        parseInt(this.getConstant("SURVEY_KYMATIO_INSIDER_RED_REFRESH")),
        parseInt(this.getConstant("SURVEY_KYMATIO_INSIDER_RED")),
        parseInt(this.getConstant("SURVEY_KYMATIO_INSIDER_GREEN")),
        parseInt(this.getConstant("SURVEY_KYMATIO_INSIDER_GREEN_REFRESH")),
        parseInt(this.getConstant("SURVEY_KYMATIO_INSIDER_BLUE")),
        parseInt(this.getConstant("SURVEY_KYMATIO_INSIDER_BLUE_REFRESH"))
      ]);
    },
    mod() {
      return _.find(this.modules, e => {
        return e.id === "security";
      });
    }
  },
  mounted() {
    this.mount();
  },
  watch: {
    isConstantsActive(value) {
      if (value === true) {
        this.mount();
      }
    }
  }
};
</script>
